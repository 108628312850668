@import '../../../css/global';

.hiddenBtn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 999;

  @include noselect;
}

.wrap {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.inner {
  width: 90%;
  max-width: 700px;
  padding: 50px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 19px;
  text-align: left;

  table {
    width: 100%;
  }
  thead {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
  }
  th,
  td {
    padding: 3px;
  }
  tr:first-child td {
    padding-top: 6px;
  }
  tr:nth-child(even) {
    background: #f5f5f5;
  }
}

.spacer {
  margin: 25px 0 0;
}

.btn {
  display: inline-block;
  padding: 4px 14px;
  margin-right: 15px;
  background: #ccc;
  border-radius: 9999px;
}

.toggle {
  display: inline-block;
  width: 54px;
  height: 27px;
  margin: 8px 15px -8px 0;
  background: #ccc;
  border-radius: 9999px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 23px;
    height: 23px;
    background: #fff;
    border-radius: 9999px;
    transition: transform 0.2s ease-in-out;
  }

  &Visible {
    background: rgb(60, 187, 60);

    &:after {
      transform: translateX(27px);
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 10px 0;
  font-size: 20px;
  line-height: 1;
  text-align: center;
}
