@import '../../../css/global';

.wrap {
  width: 100%;
  height: 0;
  padding: 0 0 100%;

  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.outer {
  position: absolute;
  top: -25%;
  left: 0;
  width: 100%;
  color: #fff;
}

.inner {
  width: 110%;
  margin: -5%;
  color: #fff;

}

.svg {
  width: 100%;

  textPath {
    color: #fff;
    fill: #fff;

  }

  tspan {
    stroke: #fff;
    color: #fff;
    font-size: 0.9rem;
  }
}


[data-theme='ergo'] {

  .svg {
  
    textPath {
      font-family: "Encode Sans", sans-serif;
      font-weight: 600;
    }
  
    tspan {
      font-size: 0.9rem;
    }
  }

}
