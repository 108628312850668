@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?v6d2kw');
  src: url('../fonts/icomoon/icomoon.eot?v6d2kw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?v6d2kw') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?v6d2kw') format('woff'),
    url('../fonts/icomoon/icomoon.svg?v6d2kw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: '\e900';
  color: #fff;
}
.icon-arrow-right:before {
  content: '\e901';
  color: #fff;
}
