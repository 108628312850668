// ======================================================================
// RESETS
// ======================================================================

html,
body {
  position: relative;
  vertical-align: baseline;
  min-width: 320px;
  // height: 100%;
  background: #fff;
  color: #000;
  overscroll-behavior: none;
}

// defaults for all elements
* {
  box-sizing: border-box;
  vertical-align: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  transform-style: flat;
  font: inherit;

  -webkit-font-smoothing: antialiased !important;
}

// // next js stuff
// #__next,
// #__next > div[class=''] {
//   position: static;
// }

// #__next {
//   min-height: 100vh;
//   min-height: 100svh;
// }

// handle json line breaks
main {
  white-space: pre-line;
}

// container elements
article,
blockquote,
div,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
  position: relative;
}

// media elements
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
img,
video {
  width: 100%;
  height: auto;
}
a img {
  border: none;
  outline: none;
}

// form elements
input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}
*::-moz-focus-inner {
  padding: 0;
  border: none;
}

// button elements
a,
button,
input[type='button'],
input[type='select'] {
  cursor: pointer;
}
button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

// misc other elements
table {
  border-collapse: collapse;
}
