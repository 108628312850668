@import '../../../css/global';

.wrap {
  padding: 0 0;
}

.brandWrap {
  // @include pxToClampMobile('margin-left', auto, 70px, auto);
}



  // Apply general styling to the form
  form {
    width: 80%; // Adjust the width as needed
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;

    p {
      font-size: 12px;
      color: red;
      margin-top: -10px;
      line-height: 1.4;

    }

    // Style labels
    label {
      color: white;
      margin-bottom: 8px;
      text-align: left;
      font-size: 12px;
      display: flex; // Enable flex layout for labels
      align-items: center; // Align items in a row
  line-height: 1.4;


      // Style checkbox
      input[type="checkbox"] {
        display: flex;
        justify-content: flex-end;
        width: 20px; // Adjust the width of the checkbox
        height: 20px;
        margin-left: 8px;
        margin-bottom: 5px;
      }

      
    }

    // Style text inputs and textarea
    input, textarea {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
      color: black; // Text color for inputs
      background-color: white;
      border: 1px solid #ccc; // Add a border for better visibility
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 16px;
    }

    textarea {
      height: 150px;
    }
  }

.error {
  margin: auto 0;
  @include pxToClampMobile('font-size', auto, 10px, auto);
  color: red;
  padding-bottom: 20px;
  letter-spacing: 3%;
  line-height: 1.4;
}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pxToClampMobile('width', auto, 165px * 0.9, auto);
  @include pxToClampMobile('height', auto, 80px * 0.9, auto);

  @include hidefont;
}

.prefix {
  margin: auto 0;
  @include pxToClampMobile('font-size', auto, 10px, auto);
  color: #fff;
  letter-spacing: 3%;;
  line-height: 1.4;

}


.intro {
  text-align: center;
  color: #fff;
  letter-spacing: 3%;;
  line-height: 1.4;
  @include pxToClampMobile('font-size', auto, 12px, auto);
  font-style: italic;
  margin-top: 2px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.disclaimer {
  text-align: left;
  color: #fff;
  letter-spacing: 3%;;
  line-height: 1.4;
  @include pxToClampMobile('font-size', auto, 8px, auto);
  font-style: italic;
  margin-top: 2px;
}
.title {
  margin: 20px auto 50px;

  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  width: 20%;
  line-height: 1.4;

  @include pxToClampMobile('font-size', auto, 20px, auto);
}
