@import '../../../css/global';

.wrap {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;

  &Active {
    pointer-events: all;
    z-index: 3;
  }
}

.outer,
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
}

.outer {
  overflow: hidden;
}

.inner {
  will-change: transform;
}

.spinner {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  will-change: transform;

  // background: #fff;
  // background-image: url(../../../images/dev-spinner.svg);
  background-size: cover;
  background-position: center;
}

.productWrap {
  display: flex;
  position: absolute;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  top: 21%;
  bottom: 12.5%;
  left: 8%;
  right: 8%;
  z-index: 4;
}

.productImage {
  width: 100%;
  max-width: 1203px;
  height: 100%;
  max-height: 1300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

.instructions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  opacity: 0;

  @include noselect;

  // z-index: 999;
  // background: rgba(255, 0, 0, 0.1);
}

.draggable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // background: rgba(255, 0, 0, 0.1);
}
