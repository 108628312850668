@import '../../../css/global';

.wrap {
  padding: 0 0 80px;
}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 160px;

  @include pxToClampMobile('width', auto, 357px * 0.9, auto);
  @include pxToClampMobile('height', auto, 162px * 0.9, auto);

 
  @include hidefont;
}

.prefix {
  margin: 20px auto 0;
  @include pxToClampMobile('font-size', auto, 48px, auto);
  color: #fff;
  line-height: 1.3;
  white-space: pre;
  font-weight: 300;

}

.title {
  margin: 20px auto 50px;

  line-height: 0.95;
  font-weight: 700;
  color: #fff;
  width: 80%;

  @include pxToClampMobile('font-size', auto, 150px, auto);
}

[data-theme='ergo'] {
  .brand {
    @include pxToClampMobile('width', auto, 657px * 0.9, auto);
    @include pxToClampMobile('height', auto, 182px * 0.9, auto);
  }

  .title {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1;
  }

  .prefix {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1.02;

  }
}

