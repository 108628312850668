@import '../../../css/global';

.wrap {
  width: percentage(calc(320 / 375));
  max-width: 320px;
  margin: 0 auto;
}

.title {
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('font-size', auto, 38px, auto);
}
