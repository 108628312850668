@import '../../../css/global';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.outer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;
}

.inner {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 40px;

  @media (max-width: 340px) and (min-height: 670px) {
    padding: 110px 0 150px;
  }
  @media (max-width: 360px) and (min-height: 710px) {
    padding: 110px 0 150px;
  }
  @media (min-height: 740px) {
    padding: 110px 0 150px;
  }

  // > * {
  //   width: 100%;
  // }
}

.pages {
  z-index: 2;
}
