@import '../../../css/global';

.wrap {
  width: percentage(calc(320 / 375));
  max-width: 100%;
  margin: 0 auto;
  // text-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
}

.prefix {
  font-weight: 600;
  letter-spacing: 3%;;
  text-transform: uppercase;


  @include pxToClampMobile('font-size', auto, 15px, auto);
}

.title {
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.01em;
  max-width: 700px;
  margin: 0 auto;


  @include pxToClampMobile('padding-top', auto, 14px, auto);
  @include pxToClampMobile('font-size', auto, 110px, auto);
}

.prize {
  width: 75%;
  margin: 0 auto;
  font-weight: 600;
  letter-spacing: -0.02em;
  letter-spacing: 3%;
  line-height: 1.2;
  text-transform: uppercase;


  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 22px, auto);
}

.collect {
  // width: 75%;
  margin: 0 auto;
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 1.3;
  width: 90%;
  max-width: 620px;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('padding-bottom', auto, 80px, auto);
  @include pxToClampMobile('font-size', auto, 48px, auto);
}

.qr {
  width: 325px;
  height: auto;
  margin: 0 auto;
  @include pxToClampMobile('padding-bottom', auto, 80px, auto);

}

.disclaimer {
  width: 55%;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: -0.02em;
  letter-spacing: 3%;
  line-height: 1.4;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);
}

[data-theme='ergo'] {
  .brand {
    @include pxToClampMobile('width', auto, 657px * 0.9, auto);
    @include pxToClampMobile('height', auto, 182px * 0.9, auto);
  }

  .title {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1.02;
  }

  .collect {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1.3;

  }
}
