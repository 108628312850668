@import '../../../css/global';

.wrap {
  @include pxToClampMobile('padding-top', auto, 20px, auto);
  @include pxToClampMobile('padding-bottom', auto, 110px, auto);
}

.title {
  width: percentage(calc(300 / 375));
  max-width: 880px;
  margin: 0 auto;

  line-height: 0.95;
  font-weight: 700;
  color: #fff;

  @include pxToClampMobile('font-size', auto, 110px, auto);
}

.body {
  color: #fff;
  line-height: 52px;
  font-weight: 300;
  @include pxToClampMobile('padding-top', auto, 80px, auto);
  @include pxToClampMobile('font-size', auto, 48px, auto);
}

[data-theme='ergo'] {

  .title {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1;
     @include pxToClampMobile('font-size', auto, 120px, auto);
  }

  .body {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1.02;

  }
}

