@import '../../../css/global';

.wrap {
  width: percentage(calc(320 / 375));
  max-width: 100%;
  margin: 0 auto;
}

.title {
  line-height: 1;
  font-weight: 700;
  color: #fff;

  @include pxToClampMobile('font-size', auto, 110px, auto);
}

.subtitle {
  font-weight: 600;
  color: #fff;
  line-height: 1.3;


  @include pxToClampMobile('padding-top', auto, 16px, auto);
  @include pxToClampMobile('font-size', auto, 48px, auto);
}

.body {
  font-weight: 300;

  margin-bottom: 100px;
  line-height: 1.3;
  @include pxToClampMobile('padding-top', auto, 34px, auto);
  @include pxToClampMobile('font-size', auto, 48px, auto);
}

[data-theme='ergo'] {
  .brand {
    @include pxToClampMobile('width', auto, 657px * 0.9, auto);
    @include pxToClampMobile('height', auto, 182px * 0.9, auto);
  }

  .title {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1;
    @include pxToClampMobile('font-size', auto, 120px, auto);
  }

  .body {
    font-family: "Encode Sans", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1.02;

  }
}
